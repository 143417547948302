import React from 'react'
import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'

import Footer from '../components/footer'
import Head from '../components/head'
import HeaderConnect from '../components/connect/header'
import Navbar from '../components/Navbar'
import PressConnect from '../components/connect/press'
import '../styles/main.scss'

import { useContactData } from '../hooks/use-contact-data'

const Connect = props => {
  const {
    yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
  } = useContactData(props.pageContext.lang)

  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        description={yoast_wpseo_metadesc}
        lang={props.pageContext.lang}
        title={yoast_wpseo_title}
        uri={props.pageContext.relativeUrl}
      />
      <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
        <Navbar
          theme="dark"
          style={{
            background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
          }}
          {...props}
        />
        <HeaderConnect {...props} />
        <PressConnect {...props} />
        <Footer {...props} style={{ marginTop: 80 }} />
      </Flex>
    </BreakpointProvider>
  )
}

export default Connect
