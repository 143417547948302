import React, { useState } from 'react'
import { Flex, Box } from '@rebass/grid'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'

//import { langs } from '../../langs'
import { OutlineButton } from '../buttons'
import { useContactData } from '../../hooks'
import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import Section from '../Section'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #2e203b;
`
const Contact = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
`

const LocationStyle = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
`

const PressStyle = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  max-width: 350px;
  color: white;
  margin-top: 20px;
  margin-bottom: 5px;
`

const TimePressStyle = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #dadada;
  margin-top: 5px;
`

const Separator = styled.div`
  border-bottom: 1px solid #543d6b;
  width: 150%;
  max-width: 350px;
`

const SeparatorMobile = styled.div`
  border-bottom: 1px solid #543d6b;
  width: 150%;
  max-width: 320px;
`

const PressConnect = props => {
  const {
    acf: {
      press_title,
      press_email,
      press_button_text,
      first_press_text,
      first_press_date,
      first_press_link,
      second_press_text,
      second_press_date,
      second_press_link,
    },
  } = useContactData(props.pageContext.lang)

  const openFirstArticle = () => {
    window.open(first_press_link, '_blank')
  }

  const openSecondArticle = () => {
    window.open(second_press_link, '_blank')
  }
  return (
    <Section py={60} css={{ position: 'relative' }} background={<Bg />}>
      <DesktopFlex>
        <Box mr={30}>
          <LocationStyle style={{ color: 'white' }}>
            {press_title}
          </LocationStyle>
          <Contact style={{ color: '#FF2B5E' }}>{press_email}</Contact>
          <OutlineButton
            color="#FF2B5E"
            hoverBackground="#FF2B5E"
            hoverColor="white"
            target="_blank"
            href="https://drive.google.com/drive/folders/1Fo0fMDORWDdYmNWb0Ph0DLYbTXEXtfBy?usp=sharing"
          >
            {press_button_text}
          </OutlineButton>
        </Box>
        <Box pl={6} style={{ cursor: 'pointer' }} onClick={openFirstArticle}>
          <Separator />
          <PressStyle> {first_press_text}</PressStyle>
          <TimePressStyle>{first_press_date}</TimePressStyle>
        </Box>
        <Box
          ml={30}
          mb={4}
          style={{ cursor: 'pointer' }}
          onClick={openSecondArticle}
        >
          <Separator />
          <PressStyle> {second_press_text}</PressStyle>
          <TimePressStyle>{second_press_date}</TimePressStyle>
        </Box>
      </DesktopFlex>
      <MobileFlex mx={'15px'} flexDirection="column">
        <Box mr={30}>
          <LocationStyle style={{ color: 'white' }}>
            {press_title}
          </LocationStyle>
          <Contact style={{ color: '#FF2B5E' }}>{press_email}</Contact>
          <OutlineButton
            color="#FF2B5E"
            hoverBackground="#FF2B5E"
            hoverColor="white"
            style={{ marginTop: '-10px' }}
            target="_blank"
            href="https://drive.google.com/drive/folders/1Fo0fMDORWDdYmNWb0Ph0DLYbTXEXtfBy?usp=sharing"
          >
            {press_button_text}
          </OutlineButton>
        </Box>
        <Box mt={'40px'} onClick={openFirstArticle}>
          <SeparatorMobile />
          <PressStyle> {first_press_text}</PressStyle>
          <TimePressStyle>{first_press_date}</TimePressStyle>
        </Box>
        <Box onClick={openSecondArticle}>
          <SeparatorMobile />
          <PressStyle> {second_press_text}</PressStyle>
          <TimePressStyle>{second_press_date}</TimePressStyle>
        </Box>
      </MobileFlex>
    </Section>
  )
}

export default PressConnect
