import React, { useState } from 'react'
import styled from 'styled-components'
import Whatsapp from '../../static/img/post/Whatsapp.svg'
import WhatsappHover from '../../static/img/post/WhatsappHover.svg'
import Twitter from '../../static/img/post/Twitter.svg'
import TwitterHover from '../../static/img/post/TwitterHover.svg'
import Facebook from '../../static/img/post/Facebook.svg'
import FacebookHover from '../../static/img/post/FacebookHover.svg'
import Linkedin from '../../static/img/post/Linkedin.svg'
import LinkedinHover from '../../static/img/post/LinkedinHover.svg'

export const SocialShareLink = ({ className, style, rest, hover, url }) => {
  const [state, setState] = useState({ hover: false })
  return (
    <div
      className={className}
      style={style}
      onMouseEnter={() => setState({ hover: true })}
      onMouseLeave={() => setState({ hover: false })}
      onClick={() => setState({ hover: false }) || window.open(url)}
    >
      {state.hover ? hover : rest}
    </div>
  )
}

export const StyledSocialShareLink = styled(SocialShareLink)`
  cursor: pointer;
`

export const WhatsappSocialLink = ({ style, text, phone }) => {
  const phonePath = phone ? `${phone}/` : ''
  const shareUrl = `https://wa.me/${phonePath}?text=${encodeURIComponent(text)}`
  return (
    <StyledSocialShareLink
      style={style}
      rest={<Whatsapp />}
      hover={<WhatsappHover />}
      url={shareUrl}
    />
  )
}

export const TwitterSocialLink = ({ style, text, url, isSharing = false }) => {
  var shareUrl = 'https://twitter.com/hubtype'
  if (isSharing) {
    shareUrl = `http://twitter.com/share?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(url)}`
  }
  return (
    <StyledSocialShareLink
      style={style}
      rest={<Twitter />}
      hover={<TwitterHover />}
      url={shareUrl}
    />
  )
}

export const FacebookSocialLink = ({ style, text, url, isSharing = false }) => {
  var shareUrl = 'https://www.facebook.com/hubtype'
  if (isSharing) {
    shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(text)}`
  }

  return (
    <StyledSocialShareLink
      style={style}
      rest={<Facebook />}
      hover={<FacebookHover />}
      url={shareUrl}
    />
  )
}

export const LinkedinSocialLink = ({ style, text, url, isSharing = false }) => {
  var shareUrl = 'https://www.linkedin.com/company/hubtype/'
  if (isSharing) {
    shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(text)}`
  }

  return (
    <StyledSocialShareLink
      style={style}
      rest={<Linkedin />}
      hover={<LinkedinHover />}
      url={shareUrl}
    />
  )
}
