import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Flex, Box } from '@rebass/grid'
import Img from 'gatsby-image'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Section from '../Section'
import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'
import {
  FacebookSocialLink,
  LinkedinSocialLink,
  TwitterSocialLink,
} from '../social/shareLinks'
import { OutlineButton } from '../buttons'
import { useContactData } from '../../hooks'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#6F16FF' },
  },
  typography: { useNextVariants: true },
})

const Title = styled.h1`
  margin-top: 40px;
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  color: black;
  align: right;
`
const Bg = styled.div`
  position: relative;
  overflow: hidden;
  top: 5%;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #ffffff;
  transform-origin: top left;
`
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 400;
  font-family: Heebo;
`

const ContactNextForm = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  cursor: pointer;
`
const Tagline = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-family: Heebo;
  font-size: 22px;
  line-height: 29px;
  color: black;
  max-width: 395px;
  margin-bottom: 4rem;
`
const LocationStyle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
`
const Separator = styled.div`
  border-bottom: 1px solid #e5e5e5;
  width: 150%;
  max-width: 250px;
`

const SeparatorMobile = styled.div`
  border-bottom: 1px solid #e5e5e5;
  width: 150%;
  max-width: 330px;
`

const FeedbackTitle = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 132.19%;
  color: #333333;
  padding-bottom: 5px;
`

const FeedbackContent = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 15px;
  color: #696973;
`

const ResetForm = styled.div`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  color: #ff2b5e;
`

const SocialLinks = ({ text, url, ml }) => (
  <Flex alignItems="row" pt={20} pb={15} ml={ml}>
    <LinkedinSocialLink
      isSharing={false}
      style={{ padding: '0px 15px' }}
      text={text}
      url={url}
    />
    <TwitterSocialLink
      isSharing={false}
      style={{ padding: '0px 15px' }}
      text={text}
      url={url}
    />
    <FacebookSocialLink
      isSharing={false}
      style={{ padding: '0px 15px' }}
      text={text}
      url={url}
    />
  </Flex>
)

const HeaderConnect = props => {
  const [userData, updateData] = useState({
    comesFrom: 'web',
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    company: '',
    subject: '',
    message: '',
    language: props.pageContext.lang,
  })

  const {
    acf: {
      header_title,
      header_subtitle,
      email_contact,
      whatsapp_field,
      barcelona_ubication_header,
      barcelona_ubication_description,
      london_ubication_header,
      london_ubication_subtitle,
      form_first_label,
      form_second_label,
      form_third_label,
      form_four_label,
      form_five_label,
      form_seven_label,
      form_six_label,
      send_button_text,
    },
  } = useContactData(props.pageContext.lang)

  const [isSend, setIsSend] = useState(false)
  const subjectOptions = ['Sales', 'Support', 'Partnership', 'Other']
  const { backgroundImage, emailIcon, whatsappIcon } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(
          relativePath: { eq: "connect/triangles@1x.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        emailIcon: file(relativePath: { eq: "icons/mail.png" }) {
          childImageSharp {
            fixed(width: 22, height: 22) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        whatsappIcon: file(relativePath: { eq: "icons/whatsapp.png" }) {
          childImageSharp {
            fixed(width: 22, height: 22) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const Background = () => {
    return (
      <Bg>
        <D>
          <Img
            imgStyle={{
              top: '145px',
              left: '45%',
              height: 'auto',
              width: 'auto',
            }}
            fluid={backgroundImage.childImageSharp.fluid}
          />
        </D>
        <M>
          <Img
            imgStyle={{
              top: '145px',
              left: '60%',
              height: 'auto',
              width: 'auto',
            }}
            fluid={backgroundImage.childImageSharp.fluid}
          />
        </M>
      </Bg>
    )
  }

  const sendClick = () => {
    if (typeof window === 'undefined') return
    window.analytics.identify(userData)
    window.analytics.track('Contact Form', userData)
    let {
      email: Email,
      firstName: FirstName,
      lastName: LastName,
      ...custom
    } = userData
    window.Autopilot.run('associate', {
      Email,
      FirstName,
      LastName,
      custom,
    })
    setIsSend(true)
    updateData({
      comesFrom: 'web',
      firstName: '',
      lastName: '',
      email: '',
      title: '',
      company: '',
      subject: '',
      message: '',
      language: props.pageContext.lang,
    })
  }

  const resetForm = () => {
    setIsSend(false)
  }

  return (
    <Section
      style={{
        paddingBottom: '30px',
      }}
      background={<Background />}
    >
      <Flex className="contact" flexDirection="column">
        <Title>{header_title}</Title>
        <Tagline>{header_subtitle}</Tagline>
      </Flex>
      <DesktopFlex flexDirection="column">
        <Flex style={{ paddingBottom: '50px' }} flexDirection="row">
          <Flex width={2 / 3} flexDirection="column">
            <MuiThemeProvider theme={theme}>
              <Form autoComplete="off">
                <Box width={1 / 2} px={2}>
                  <TextField
                    required
                    style={{ display: 'flex' }}
                    id="outlined-f_name"
                    label={form_first_label}
                    onChange={props =>
                      updateData({ ...userData, firstName: props.target.value })
                    }
                    value={userData.firstName}
                    margin="normal"
                    variant="outlined"
                  />
                </Box>
                <Box width={1 / 2} px={2}>
                  <TextField
                    style={{ display: 'flex' }}
                    id="outlined-dense"
                    label={form_second_label}
                    onChange={props =>
                      updateData({ ...userData, lastName: props.target.value })
                    }
                    value={userData.lastName}
                    margin="normal"
                    variant="outlined"
                  />
                </Box>
                <Box width={1 / 2} px={2}>
                  <TextField
                    required
                    style={{ display: 'flex' }}
                    id="outlined-email-input"
                    label={form_third_label}
                    type="email"
                    name="email"
                    onChange={props =>
                      updateData({ ...userData, email: props.target.value })
                    }
                    value={userData.email}
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                  />
                </Box>
                <Box width={1 / 2} px={2}>
                  <TextField
                    style={{ display: 'flex' }}
                    id="outlined-dense"
                    label={form_four_label}
                    onChange={props =>
                      updateData({ ...userData, title: props.target.value })
                    }
                    value={userData.title}
                    margin="normal"
                    variant="outlined"
                  />
                </Box>
                <Box width={1 / 2} px={2}>
                  <TextField
                    style={{ display: 'flex' }}
                    id="outlined-dense"
                    label={form_five_label}
                    onChange={props =>
                      updateData({ ...userData, company: props.target.value })
                    }
                    value={userData.company}
                    margin="normal"
                    variant="outlined"
                  />
                </Box>
                <Box width={1 / 2} px={2}>
                  <TextField
                    style={{ display: 'flex' }}
                    select
                    label={form_six_label}
                    margin="normal"
                    variant="outlined"
                    onChange={props =>
                      updateData({ ...userData, subject: props.target.value })
                    }
                    value={userData.subject}
                  >
                    {subjectOptions.map((option, i) => {
                      return (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Box>
                <Box width={1} px={2}>
                  <TextField
                    required
                    id="outlined-full-width"
                    label={form_seven_label}
                    margin="normal"
                    fullWidth
                    multiline={true}
                    rows={6}
                    rowsMax={10}
                    variant="outlined"
                    style={{ color: 'black' }}
                    onChange={props =>
                      updateData({ ...userData, message: props.target.value })
                    }
                    value={userData.message}
                  />
                </Box>
              </Form>
            </MuiThemeProvider>
            {isSend && (
              <Flex flexDirection="column">
                <FeedbackTitle>Success! Message sent.</FeedbackTitle>
                <FeedbackContent>
                  A team member will reach out to you within one business day.
                </FeedbackContent>
                <Flex pt={3} flexDirection="row">
                  <OutlineButton
                    color="white"
                    hoverBackground="#FF4B76"
                    style={{
                      background: '#FF2B5E',
                      marginLeft: '6px',
                      height: '40px',
                      maxWidth: '140px',
                    }}
                    href="/"
                  >
                    GO TO HOME
                  </OutlineButton>
                </Flex>
              </Flex>
            )}
            {!isSend && (
              <OutlineButton
                color="white"
                hoverBackground="#FF4B76"
                style={{
                  background: '#FF2B5E',
                  marginLeft: '6px',
                  height: '40px',
                  maxWidth: '140px',
                }}
                onClick={sendClick}
              >
                {send_button_text}
              </OutlineButton>
            )}
          </Flex>
          <Flex pt={3} width={1 / 3} flexDirection="column" alignItems="center">
            <Flex flexDirection="column">
              <Flex mb={'-15px'} flexDirection="row">
                <Img
                  style={{ top: '3px' }}
                  fixed={emailIcon.childImageSharp.fixed}
                />
                <ContactNextForm
                  onClick={() => (window.location = 'mailto:hi@hubtype.com')}
                  style={{ color: '#FF2B5E', marginLeft: '10px' }}
                >
                  {email_contact}
                </ContactNextForm>
              </Flex>
              {/* <Flex flexDirection="row">
                <Img
                  style={{ top: '3px' }}
                  fixed={whatsappIcon.childImageSharp.fixed}
                />
                <ContactNextForm
                  style={{ color: '#128C7E', marginLeft: '10px' }}
                >
                  Whatsapp
                </ContactNextForm>
              </Flex> */}
              <Separator />
            </Flex>
            <Flex flexDirection="column">
              <SocialLinks
                ml={'-65px'}
                text="Hubtype"
                url="https://www.hubtype.com"
              />
            </Flex>
            <Flex flexDirection="column" ml={'-10px'}>
              <Separator />
              <Box pt={4} pb={3}>
                <LocationStyle style={{ color: '#333333', fontWeight: 'bold' }}>
                  {barcelona_ubication_header}
                </LocationStyle>
                <LocationStyle
                  style={{ color: '#696973', fontWeight: 'normal' }}
                  dangerouslySetInnerHTML={{
                    __html: barcelona_ubication_description,
                  }}
                />
              </Box>
              <Box>
                <LocationStyle style={{ color: '#333333', fontWeight: 'bold' }}>
                  {london_ubication_header}
                </LocationStyle>
                <LocationStyle
                  style={{ color: '#696973', fontWeight: 'normal' }}
                  dangerouslySetInnerHTML={{
                    __html: london_ubication_subtitle,
                  }}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </DesktopFlex>
      <MobileFlex flexDirection="column">
        <Flex
          mx={15}
          mt={'-20px'}
          style={{ paddingBottom: '50px' }}
          flexDirection="column"
        >
          <Flex flexDirection="column">
            <Flex mr="8px" flexDirection="row">
              <Img
                style={{ top: '3px' }}
                fixed={emailIcon.childImageSharp.fixed}
              />
              <ContactNextForm
                onClick={() => (window.location = 'mailto:hi@hubtype.com')}
                style={{ color: '#FF2B5E', marginLeft: '10px' }}
              >
                {email_contact}
              </ContactNextForm>
            </Flex>
            {/* <Flex mr="8px" flexDirection="row">
              <Img
                style={{ top: '3px' }}
                fixed={whatsappIcon.childImageSharp.fixed}
              />
              <ContactNextForm style={{ color: '#128C7E', marginLeft: '10px' }}>
                Whatsapp
              </ContactNextForm>
            </Flex> */}
            <SeparatorMobile />
          </Flex>
          <Flex flexDirection="column">
            <SocialLinks
              ml={'-10px'}
              text="Hubtype"
              url="https://www.hubtype.com"
            />
            <SeparatorMobile />
          </Flex>
          <Flex flexDirection="column">
            <MuiThemeProvider theme={theme}>
              <Form noValidate autoComplete="off">
                <TextField
                  fullWidth
                  id="outlined-f_name"
                  label={form_first_label}
                  onChange={props =>
                    updateData({ ...userData, firstName: props.target.value })
                  }
                  value={userData.firstName}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  id="outlined-dense"
                  label={form_second_label}
                  onChange={props =>
                    updateData({ ...userData, lastName: props.target.value })
                  }
                  value={userData.lastName}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  id="outlined-email-input"
                  label={form_third_label}
                  type="email"
                  name="email"
                  onChange={props =>
                    updateData({ ...userData, email: props.target.value })
                  }
                  value={userData.email}
                  autoComplete="email"
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  id="outlined-dense"
                  label={form_four_label}
                  onChange={props =>
                    updateData({ ...userData, title: props.target.value })
                  }
                  value={userData.title}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  id="outlined-dense"
                  label={form_five_label}
                  onChange={props =>
                    updateData({ ...userData, company: props.target.value })
                  }
                  value={userData.company}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  select
                  label={form_six_label}
                  margin="normal"
                  variant="outlined"
                  onChange={props =>
                    updateData({ ...userData, subject: props.target.value })
                  }
                  value={userData.subject}
                >
                  {subjectOptions.map((option, i) => {
                    return (
                      <MenuItem key={i} value={option}>
                        {option}
                      </MenuItem>
                    )
                  })}
                </TextField>
                <TextField
                  id="outlined-full-width"
                  label={form_seven_label}
                  margin="normal"
                  fullWidth
                  multiline={true}
                  rows={6}
                  rowsMax={10}
                  variant="outlined"
                  onChange={props =>
                    updateData({ ...userData, message: props.target.value })
                  }
                  value={userData.message}
                />
              </Form>
            </MuiThemeProvider>
            {isSend && (
              <Flex mx={15} flexDirection="column">
                <FeedbackTitle>Success! Message sent.</FeedbackTitle>
                <FeedbackContent>
                  A team member will reach out to you within one business day.
                </FeedbackContent>
                <Flex pt={3} mx={-15} flexDirection="row">
                  <OutlineButton
                    color="white"
                    hoverBackground="#FF4B76"
                    style={{
                      background: '#FF2B5E',
                      marginLeft: '6px',
                      height: '40px',
                      maxWidth: '140px',
                    }}
                    href="/"
                  >
                    GO TO HOME
                  </OutlineButton>
                  <OutlineButton
                    color="#FF2B5E"
                    hoverBackground="transparent"
                    style={{
                      background: 'white',
                      marginLeft: '6px',
                      height: '40px',
                      maxWidth: '300px',
                    }}
                    onClick={resetForm}
                  >
                    SEND ANOTHER MESSAGE
                  </OutlineButton>
                </Flex>
              </Flex>
            )}
            {!isSend && (
              <OutlineButton
                color="white"
                hoverBackground="#FF4B76"
                style={{
                  background: '#FF2B5E',
                  marginTop: '-10px',
                  marginLeft: '10px',
                  height: '40px',
                  maxWidth: '140px',
                }}
                onClick={sendClick}
              >
                {send_button_text}
              </OutlineButton>
            )}
          </Flex>
          <Flex pt={3} flexDirection="column">
            <Flex flexDirection="column" pt={4}>
              <SeparatorMobile />
              <Box pt={4} pb={3}>
                <LocationStyle style={{ color: '#333333', fontWeight: 'bold' }}>
                  {barcelona_ubication_header}
                </LocationStyle>
                <LocationStyle
                  style={{ color: '#696973', fontWeight: 'normal' }}
                  dangerouslySetInnerHTML={{
                    __html: barcelona_ubication_description,
                  }}
                />
              </Box>
              <Box>
                <LocationStyle style={{ color: '#333333', fontWeight: 'bold' }}>
                  {london_ubication_header}
                </LocationStyle>
                <LocationStyle
                  style={{ color: '#696973', fontWeight: 'normal' }}
                  dangerouslySetInnerHTML={{
                    __html: london_ubication_subtitle,
                  }}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </MobileFlex>
    </Section>
  )
}

export default HeaderConnect
